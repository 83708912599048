<template>
  <div class="withdrawPage bgfff" style="background-color: #fff">
    <div class="app h5MaxWidth">
      <header class="isScrollTo noColor">
        <div class="header h5MaxWidth clear display alignCenter">
          <div
            class="back display alignCenter justifyCenter"
            onclick="window.history.go(-1)"
          >
            <i class="iconfont icon-arrow-left"></i>
          </div>
          <h2 class="flex1">Withdraw</h2>
          <div class="navskip" id="sideMenu"></div>
        </div>
      </header>
      <div class="topRegion">
        <div class="statistics">
          <ul class="display alignCenter">
            <li class="balance">
              <p>
                <em class="currency">₹</em
                >{{ parseFloat(info.cash) + parseFloat(info.givingMoney) }}
              </p>
              <span>Withdrawable</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="main">
        <div class="drawmoney forms">
          <form id="drawmoney" action="#" method="post" onsubmit="">
            <ul v-if="type === 0">
              <li>
                <div class="inputArea display alignCenter">
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="text"
                      v-model="realname"
                      id="withdrawalAmount"
                      class="withdrawalAmount fillArea inrICO inputIcon flex1"
                      placeholder="Realname"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="inputArea display alignCenter">
                  <div class="trueInpt display alignCenter flex1">
                    <div @click="show = true" style="padding: 18px 10px">
                      {{ bankCodeName || "Click To Choice Bank" }}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="inputArea display alignCenter">
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="text"
                      v-model="bankAccount"
                      id="withdrawalAmount"
                      class="withdrawalAmount fillArea inrICO inputIcon flex1"
                      placeholder="Bank Account"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="inputArea display alignCenter">
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="text"
                      v-model="IFSC"
                      id="withdrawalAmount"
                      class="withdrawalAmount fillArea inrICO inputIcon flex1"
                      placeholder="IFSC"
                    />
                  </div>
                </div>
              </li>
            </ul>
            <ul class="type1" v-if="type === 1">
              <li>
                <div class="inputArea display alignCenter">
                  <span style="font-size: 16px; margin-right: 20px">₹</span>
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="text"
                      v-model="amount"
                      style="height: 20px"
                      id="withdrawalAmount"
                      class="withdrawalAmount fillArea inrICO inputIcon flex1"
                      placeholder="Withdrawal Amount"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="inputArea display alignCenter">
                  <span style="font-size: 16px; margin-right: 20px"
                    >Trade Password</span
                  >
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="text"
                      v-model="password"
                      style="height: 20px"
                      id="withdrawalAmount"
                      class="withdrawalAmount fillArea inrICO inputIcon flex1"
                      placeholder="Trade Password"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="inputArea display alignCenter">
                  <span style="font-size: 16px; margin-right: 20px"
                    >Realname</span
                  >
                  <div class="trueInpt display alignCenter flex1">
                    {{ bankInfo.name }}
                  </div>
                </div>
              </li>
              <li>
                <div class="inputArea display alignCenter">
                  <span style="font-size: 16px; margin-right: 20px"
                    >Account</span
                  >
                  <div class="trueInpt display alignCenter flex1">
                    {{ bankInfo.account }}
                  </div>
                </div>
              </li>
            </ul>
            <div
              class="actionBar display alignCenter justifyEnd"
              v-if="type === 0"
            >
              <div
                class="drawmoneyBtn clickBtn radius50P display alignCenter justifyCenter"
                @click="submitData()"
              >
                <p>Save</p>
                <i class="iconfont icon-arrow-right-3"></i>
              </div>
            </div>
            <div
              class="actionBar display alignCenter justifyEnd"
              v-if="type === 1"
            >
              <div
                class="drawmoneyBtn clickBtn radius50P display alignCenter justifyCenter"
                @click="submitData1()"
              >
                <p>Submit withdraw</p>
                <i class="iconfont icon-arrow-right-3"></i>
              </div>
            </div>
          </form>
        </div>

        <div class="tipsBox">
          <div id="tips" class="tips">
            <div class="titleBar display alignCenter justifyDetween">
              <div>
                <h3>Explain</h3>
              </div>
            </div>
            <div class="explain">
              <p>1. The daily withdrawal time is from 10:00:00 to 18:00:00</p>
              <p>2. The single withdrawal amount is between 180 and 50000</p>
              <p>
                3. In order to facilitate financial settlement, you can only
                apply for cash withdrawal 1 times a day
              </p>
              <p>4. Withdrawal tax rate: 6.0%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      info: {},
      realname: "",
      bankAccount: "",
      bankInfo: {},
      IFSC: "",
      bankCode: "",
      bankCodeName: "",
      otp: "",
      uid: "",
      actions: [{ name: "选项一" }, { name: "选项二" }, { name: "选项三" }],
      amount: "",
      password: "",
      cashFee: "",
      uInfo: "",
      type: 0, //0未绑卡1已绑卡
    };
  },
  components: {},
  methods: {
    onSelect(item) {
      this.show = false;
      this.bankCode = item.value;
      this.bankCodeName = item.name;
    },
    submitData() {
      //绑卡
      this.getAjax(
        "api/user/account",
        {
          uid: this.uid,
          otp: this.otp,
          name: this.realname,
          bankCode: this.bankCode,
          account: this.bankAccount,
          IFSC: this.IFSC,
        },
        "post"
      ).then((res) => {
        if (res.code === 200) {
          this.tips("success");
          this.initPage();
        } else {
          this.tips(res.msg, "warning");
        }
      });
    },
    submitData1() {
      //提现
      var cash = this.cashFee * 100 + "%"; //手续比例
      var amount1 = ((1 - this.cashFee) * this.amount).toFixed(2); //实际金额
      var msg =
        "Withdrawals need to deduct " +
        cash +
        " of the withdrawal tax, the actual withdrawal is " +
        amount1 +
        " RS, complete this operation";
      this.$dialog
        .confirm({
          message: msg,
          confirmButtonText: "YES",
          cancelButtonText: "NO",
        })
        .then(() => {
          this.getAjax(
            "api/withdrawal/index",
            {
              uid: this.uid,
              tradePwd: this.password,
              amount: this.amount,
            },
            "post"
          ).then((res) => {
            if (res.code === 200) {
              this.tips("success");
            } else {
              this.tips(res.msg, "warning");
            }
          });
        });
    },
    initPage() {
      this.getAjax("api/user/account?uid=" + this.uid, {}, "get").then(
        (res) => {
          this.info = res.info;
          if (res.detail) {
            //有值表示已绑卡
            this.type = 1;
          } else {
            this.type = 0;
            this.actions = [];
            Object.keys(res.bank).forEach((e) => {
              this.actions.push({
                name: res.bank[e],
                value: e,
              });
            });
          }
        }
      );
      this.getAjax("api/withdrawal/index?uid=" + this.uid, {}, "get").then(
        (res) => {
          if (res.info) {
            this.bankInfo = res.bank;
            this.cashFee = res.cashFee;
          }
        }
      );
    },
  },
  mounted() {
    this.uid = this.getItem("uid");
    this.initPage();
  },
};
</script>

<style scoped lang="less">
.type1{
  .inputArea{
    padding: 10px;
  }
}
</style>
